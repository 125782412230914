/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 gameboy.glb 
*/

import React, { useLayoutEffect, useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import gsap from 'gsap';
import { useThree } from '@react-three/fiber';


export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('./3dmodel/gameboy.glb')

  let camera = useThree((state) => state.camera);
  let scene = useThree((state) => state.scene);

  useLayoutEffect(() => {
    camera.position.set(-150, 0, 250);

    let fov = camera.fov;

    // fov = (1400 * 20) / window.innerWidth;

    // console.log(fov);
    
    // camera.fov = fov;

    // camera.updateProjectionMatrix();

    let mm = gsap.matchMedia();
  
    mm.add({
      isDesktop: `(min-width: 48em)`,
      isMobile: `(max-width:48em`,
    }, (context) => {
      let { isDesktop, isMobile } = context.conditions;

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#gameboy-model",
        start: "top+=350 top",
        endTrigger: "#battery",
        end: "top top",
        scrub: true,
      }
    });
      
      
      tl.fromTo(camera.position, { x: isDesktop ? -200 : -250, y: isMobile ? -120 : 0, z: 300 }, { x: 0, y: 0, z: 180 })
        .to(camera.position, { x: 10, y: 0, z: 250 })
        .to(scene.rotation, { x: -0.5, y: 0, z: 0.0, duration: 1.25 })
        .to(scene.rotation, { x: -0.2, y: 0.8, z: 0.7, duration: 1.25 })
        .to(scene.rotation, { x: 0, y: 2.95, z: 0 })
        .to(scene.rotation, { x: 0, y: 6.2, z: 0 })
        .to(camera.position, { x: isDesktop ? -225 : 0, y: 0, z: 250, delay: 0.75 , duration: 2.25 })
        .to(camera.position, { x: isDesktop ? 255 : 0, y: 0, z: 300 });
      
      if (isMobile) {
        camera.fov = 90;
        camera.updateProjectionMatrix();
      }

      return() => {
        if(tl) {
          tl.kill();
        }
      }
    }
    );
  });

  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box001_default_0.geometry} material={materials['default']} />
        <mesh geometry={nodes.Box001_default_0001.geometry} material={materials.default_0} />
      </group>
      <mesh geometry={nodes['Plane001_Material_#129_0'].geometry} material={materials.Material_129} rotation={[-Math.PI / 2, 0, 0]} />
    </group>
  )
}

useGLTF.preload('/3dmodel/gameboy.glb')
